









import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import HseRoutineCategory from '@/shared/modules/hse-routine-categories/hse-routine-category.model'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import CreateRoutineCategoriesDialog from "@/admin/components/views/hse/routine-categories/CreateRoutineCategoriesDialog.vue";
import { getNodeApiUrlByVersion } from "@/config";
import RoutineCategoryService from "@/shared/modules/hse-routine-categories/hse-routine-categories.service";
import EditRoutineCategoriesDialog from "@/admin/components/views/hse/routine-categories/EditRoutineCategoriesDialog.vue";

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class RoutineCategoriesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(HseRoutineCategory)
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-routine-categories`)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setText(__('admin.views.hse.routine-categories.index.table.columns.name')),
      ])
      .setActions([
        new EditAction().setAction(this.openEditDialog),
        new DeleteAction().setAction(this.deleteCategory)
      ])
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateRoutineCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.hse.routine-categories.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(item: HseRoutineCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditRoutineCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.hse.routine-categories.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteCategory(item: HseRoutineCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('admin.views.hse.routine-categories.index.table.actions.delete.title'),
        text: __('admin.views.hse.routine-categories.index.table.actions.delete.text', { name: item.name }),
        onYes: () => RoutineCategoryService.destroy(item.uuid)
            .then(() => {
              this.tableRef.refresh()
              this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.success,
                message: __('admin.views.hse.routine-categories.index.table.actions.delete.on-success'),
              })
            })
            .catch(() => {
              this.$store.dispatch(GlobalActions.showSnackBar, {
                type: SnackBarTypes.error,
                message: __('admin.views.hse.routine-categories.index.table.actions.delete.on-error'),
              })
            })
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
