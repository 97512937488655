
















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import HseRoutineCategory from "@/shared/modules/hse-routine-categories/hse-routine-category.model";
import { getNodeApiUrlByVersion } from "@/config";
import { validateFields } from "@/shared/helpers/validate";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CreateRoutineCategoriesDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null
  loading: boolean = false

  created() {
    let fields = [
      new Field()
        .setKey('name')
        .setSize(FieldSizes.full)
        .isRequired()
        .setTranslatable(true)
        .setTitle(__('admin.components.hse.routine-categories.create-dialog.form.name')),
    ]

    this.form = new FormBase()
      .setEndpoint(`${getNodeApiUrlByVersion()}/hse-routine-categories`)
      .setTranslatable(true)
      .setMethod(HttpMethod.POST)
      .setModel(HseRoutineCategory)
      .setFields(fields)
      .setValidate(true, { onlyRegionLangRequired: true })
      .setShowDefaultValidationError(true)
      .setOnSuccess(this.meta.onSuccess)
  }

  private onError() {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.error,
      message: __("validation.fill-all-field"),
    });
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
