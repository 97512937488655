import { getNodeApiUrlByVersion } from '@/config'
import Service from '@/shared/classes/service'

export default class RoutineCategoryService extends Service {
  static prefix = 'hse-routine-categories'

  static destroy(id: string): Promise<any> {
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }

  static show(id: string): Promise<any> {
    return this.get(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }
}
